import backend from '@/services/backend'

export default {

  fetchUniverseUsersData (authToken, universeName) {
    return backend.get('vuejs/users/universe/' + universeName, { headers: { Authorization: authToken } })
  },

  fetchUsersListData (authToken, surveyName) {
    return backend.get('vuejs/users/list/' + surveyName +'?status=1', { headers: { Authorization: authToken } })
  },

  fetchUserDetailsData (authToken, surveyName, userId) {
    return backend.get('vuejs/users/details/' + surveyName + '/' + userId, { headers: { Authorization: authToken } })
  },

  updateUserDetailsData(authToken, surveyName, userId, userDetails) {
    return backend({ method: 'post', url: 'vuejs/users/details/' + surveyName + '/' + userId, data: userDetails,  headers: { Authorization: authToken } })
  },

  getQuestionFilterSuggestions(authToken, surveyName, questionId, searchTerm) {
    return backend.get('vuejs/users/questionfiltersuggestions/' + surveyName + '/' + questionId + '/' + searchTerm, {headers: {Authorization: authToken}})
  },

  fetchUserEditorData(authToken, surveyName, userId) {
    return backend.get('vuejs/users/editordata/' + surveyName + '/' + userId, { headers: { Authorization: authToken } })
  },

  updateUserEditorData(authToken, surveyName, userEditorData) {
    return backend({ method: 'post', url: 'vuejs/users/editordata/' + surveyName, data: userEditorData,  headers: { Authorization: authToken } })
  },

    fetchKeyCloakData(authToken, surveyName, email) {
      return backend.get('vuejs/users/keycloakdata/' + surveyName + '/' + email, { headers: { Authorization: authToken } })
    }
}
